import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;

export default function CreateCard() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [soldout, setSoldout] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [handCategory, setHandCategory] = useState("");
  const [size, setSize] = useState("");

  const [mainPhoto, setMainPhoto] = useState(null);
  const [backPhoto, setBackPhoto] = useState(null);
  const [carouselPhotos, setCarouselPhotos] = useState([]);

  const [draggedFileIndex, setDraggedFileIndex] = useState(null);

  const handleFileDragStart = (index) => {
    setDraggedFileIndex(index);
  };

  const handleFileDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileDrop = (index) => {
    if (draggedFileIndex === null) return;

    const updatedFiles = [...carouselPhotos];
    const [draggedFile] = updatedFiles.splice(draggedFileIndex, 1);
    updatedFiles.splice(index, 0, draggedFile);

    setCarouselPhotos(updatedFiles);
    setDraggedFileIndex(null);
  };

  const handleFileRemove = (index) => {
    setCarouselPhotos(carouselPhotos.filter((_, i) => i !== index));
  };

  const handleCarouselPhotosChange = (event) => {
    const files = Array.from(event.target.files);
    setCarouselPhotos([...carouselPhotos, ...files]);
  };

  const handleMainPhotoChange = (event) => {
    setMainPhoto(event.target.files[0]);
  };

  const handleBackPhotoChange = (event) => {
    setBackPhoto(event.target.files[0]);
  };

  const handleNameChange = (event) => {
    if (event.target.value === "HESOYAM") {
      setName("TEST");
      setDescription("TEST TEST");
      setPrice(1000);
      setCategory("KIMONO");
      setSize("S");
    }

    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleisSoldOutChange = () => {
    setSoldout(!soldout);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setHandCategory("");
    setCategory(event.target.value);
  };

  const handleHandCategoryChange = (event) => {
    setCategory(event.target.value);
    setHandCategory(event.target.value);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const confirmDeleteCategoty = useCallback(async () => {
    const token = Cookies.get("token");

    if (!token) {
      navigate("/login");
    }

    try {
      const response = await fetch(`${API_URL}/api/category`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify({
          category: category,
        }),
      });

      if (response.ok) {
        setCategory("");
        alert("Категория удалена!");
        return;
      }

      alert("Категория не удалена. Детали в консоли (F12)");
    } catch (e) {
      console.log(e);
      alert(e);
    }
  }, [navigate, category]);

  const confirmChangeRequest = async () => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/login");
      return;
    }

    if (mainPhoto && backPhoto && carouselPhotos.length) {
      try {
        const response = await fetch(`${API_URL}/api/card`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
          body: JSON.stringify({
            name,
            description,
            price,
            sizes: size,
            category_id: category,
            soldout: soldout,
          }),
        });

        if (!response.ok) {
          setMessage("Что-то пошло не так");
          return;
        }

        const card = await response.json();
        const { id } = card;

        const data = new FormData();
        data.append("mainPhoto", mainPhoto);
        data.append("backPhoto", backPhoto);
        carouselPhotos.forEach((file) => {
          data.append("carouselPhotos[]", file);
        });

        const response2 = await fetch(`${API_URL}/api/card/${id}/photos`, {
          method: "POST",
          headers: {
            token: token,
          },
          body: data,
        });

        if (response2.ok) {
          setMessage("Успешно добавлено!");
          setTimeout(() => {
            navigate("/home");
          }, 1000);
        } else {
          setMessage("Что-то пошло не так");
        }
      } catch (error) {
        console.log(error);
        setMessage("Что-то пошло не так");
      }
    } else {
      setMessage("Фото не добавлены");
    }
  };

  useEffect(() => {
    const getCategories = async () => {
      const token = Cookies.get("token");
      if (!token) {
        navigate("/login");
        return;
      }
      try {
        const response = await fetch(`${API_URL}/api/categories`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        });

        if (response.ok) {
          const { rows } = await response.json();
          setAllCategories(rows);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getCategories();
  }, [navigate]);

  return (
    <div className="edit-mode">
      <div className="edit-header">
        Название
        <input
          className="edit-input"
          type="text"
          placeholder="..."
          value={name}
          onChange={handleNameChange}
        />
      </div>

      <div className="edit-header">
        Цена
        <input
          className="edit-input"
          type="number"
          placeholder="RUB"
          value={price}
          onChange={handlePriceChange}
        />
      </div>

      <div className="edit-header">
        {category ? (
          <div className="edit-header">Выбрана категория: "{category}"</div>
        ) : (
          <div className="edit-header">Категория не выбрана</div>
        )}
        <select
          className="edit-input"
          value={category}
          onChange={handleCategoryChange}
        >
          <option>Не выбрано</option>
          {allCategories.map((el, i) => (
            <option value={el.name} key={i}>
              {el.name}
            </option>
          ))}
        </select>
        <input
          className="edit-input"
          placeholder="Новая..."
          type="text"
          value={handCategory}
          onChange={handleHandCategoryChange}
        />
      </div>

      {!handCategory && category && (
        <button
          tabIndex="-1"
          className="edit-btn-delete"
          onClick={confirmDeleteCategoty}
        >
          Удалить категорию
        </button>
      )}

      <div className="edit-header">
        Размеры
        <input
          className="edit-input"
          placeholder="S, L, XL, ..."
          type="text"
          value={size}
          onChange={handleSizeChange}
        />
      </div>

      <div className="edit-header">
        Описание
        <textarea
          className="edit-textarea"
          placeholder="..."
          value={description}
          onChange={handleDescriptionChange}
        />
      </div>

      <div className="edit-header checkbox">
        <div className="one-size-text">Товар продан</div>
        <div className="toggle-ios">
          <input
            type="checkbox"
            id="toggle"
            className="checkbox-ios"
            onChange={handleisSoldOutChange}
            checked={soldout}
          />
          <label htmlFor="toggle" className="label-ios"></label>
        </div>
      </div>

      <div className="edit-header checkbox">
        <div className="one-size-text">Главное фото</div>
        <label className="custom-file-upload">
          <input type="file" onChange={handleMainPhotoChange} />
        </label>
      </div>

      <div className="edit-header checkbox">
        <div className="one-size-text">Второе главное фото</div>
        <label className="custom-file-upload">
          <input type="file" onChange={handleBackPhotoChange} />
        </label>
      </div>

      <div className="edit-header checkbox">
        <div className="one-size-text">Другие фото продукта</div>
        <input
          type="file"
          multiple
          onChange={handleCarouselPhotosChange}
        />
      </div>

      <div className="carousel-photos">
        <ul>
          {carouselPhotos.map((file, index) => (
            <li
              key={index}
              draggable
              onDragStart={() => handleFileDragStart(index)}
              onDragOver={handleFileDragOver}
              onDrop={() => handleFileDrop(index)}
            >
              {file.name}
              <button onClick={() => handleFileRemove(index)}>Удалить</button>
            </li>
          ))}
        </ul>
      </div>

      <div className="edit-btns">
        <button
          tabIndex="-1"
          className="edit-btn-save"
          onClick={confirmChangeRequest}
        >
          {message ? message : "Сохранить и закрыть"}
        </button>
      </div>
    </div>
  );
}
