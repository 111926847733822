import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";
import Modal from "react-modal";
const API_URL = process.env.REACT_APP_API_URL;

// Стиль модалки
const customStyles = {
  overlay: {
    zIndex: "11",
  },
  content: {
    color: "black",
    width: "100%",
    height: "100%",
    padding: "0",
    inset: "0",
  },
};

export default function ProductDetails() {
  const Navigate = useNavigate();
  const { id } = useParams();
  const [item, setItem] = useState({});
  const [size, setSize] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
    Navigate("/home");
  };

  useEffect(() => {
    const token = Cookies.get("token");
    const getItem = async () => {
      try {
        const response = await fetch(`${API_URL}/api/card/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setItem(data);
          setIsLoading(false);
          
          return;
        }
        alert("Что-то пошло не так, откройте консоль (F12)");
      } catch (e) {
        console.log(e);
        alert(e);
      }
    };
    getItem();
  }, [id]);

  if (!isLoading) {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal"
      >
        <div className="close-modal" onClick={closeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </div>

        <div className="modal-card">
          {item.carouselphotos.length ? (
            <div className="modal-card-slider">
              <Splide>
                {item.carouselphotos.map((ph, key) => (
                  <SplideSlide key={key} rewind="true">
                    <div className="slider-img">
                      <img src={ph} alt={`Slide ${key}`} />
                    </div>
                  </SplideSlide>
                ))}
              </Splide>
            </div>
          ) : (
            <div>Нет фото</div>
          )}
          <div className="modal-card-info">
            <div className="card-title-modal">{item.name}</div>
            <div className="card-description-modal">{item.description}</div>
            <div className="size-grid">
              {item.sizes.length ? (
                item.sizes.split(",").map((_size, key) => {
                  return (
                    <div
                      className={_size === size ? "pick size-btn" : "size-btn"}
                      key={key}
                      onClick={() => setSize(_size)}
                    >
                      {_size}
                    </div>
                  );
                })
              ) : (
                <div></div>
              )}
            </div>
            <div className="card-before-purchase">
              {item.soldout ? (
                <div className="card-btn-soldout-modal">SOLD OUT</div>
              ) : (
                <div>
                  <div className="card-price-modal">/{item.price} RUB</div>
                  <div className="card-btn-buy-modal">BUY NOW</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  } else {
    return <div>Loading...</div>;
  }
}
