import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Logo from "./QM_LOGO.png";
import Cookies from "js-cookie";

export default function AdminPanel({ responseData }) {
  const navigate = useNavigate();

  const logOut = () => {
    Cookies.remove("token");
    navigate("/login");
  };

  return (
    <div className="admin-panel">
      <div className="tool-bar">
        <div
          className="logo"
          style={{
            backgroundImage: `url(${Logo})`,
            backgroundPosition: "center",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
            backgroundColor: "transparent",
          }}
        ></div>

        <Link className="tool-btn" to="/home">
          {"Посмотреть"}
        </Link>
        <Link className="tool-btn" to="/home/card/new">
          {"Создать "}
        </Link>

        <div className="tool-btn" onClick={logOut}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2rem"
            height="2rem"
            fill="currentColor"
            className="bi bi-box-arrow-left"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"
            />
            <path
              fillRule="evenodd"
              d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708z"
            />
          </svg>
        </div>
      </div>
      <div className="result-content container cards">
        <Outlet />
      </div>
    </div>
  );
}
