import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;

export default function ChangeCard() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [item, setItem] = useState({});
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [soldout, setSoldout] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [handCategory, setHandCategory] = useState("");
  const [size, setSize] = useState("");

  const [mainPhoto, setMainPhoto] = useState(null);
  const [backPhoto, setBackPhoto] = useState(null);
  const [carouselPhotos, setCarouselPhotos] = useState([]);

  const [draggedFileIndex, setDraggedFileIndex] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get("token");

      if (!token) {
        navigate("/login");
        return;
      }

      try {
        const itemResponse = await fetch(`${API_URL}/api/card/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        });

        if (itemResponse.ok) {
          const itemData = await itemResponse.json();
          setItem(itemData);
          setName(itemData.name);
          setDescription(itemData.description);
          setPrice(itemData.price);
          setSoldout(itemData.soldout || false);
          setSize(itemData.sizes);


          const categoriesResponse = await fetch(`${API_URL}/api/categories`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: token,
            },
          });

          if (categoriesResponse.ok) {
            const { rows } = await categoriesResponse.json();
            setAllCategories(rows);
            findNameByID(itemData.category_id, rows);
          }

          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [id, navigate]);

  const handleFileDragStart = (index) => {
    setDraggedFileIndex(index);
  };

  const handleFileDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileDrop = (index) => {
    if (draggedFileIndex === null) return;

    const updatedFiles = [...carouselPhotos];
    const [draggedFile] = updatedFiles.splice(draggedFileIndex, 1);
    updatedFiles.splice(index, 0, draggedFile);

    setCarouselPhotos(updatedFiles);
    setDraggedFileIndex(null);
  };

  const handleFileRemove = (index) => {
    setCarouselPhotos(carouselPhotos.filter((_, i) => i !== index));
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setCarouselPhotos([...carouselPhotos, ...files]);
  };

  const handleMainPhotoChange = (event) => {
    setMainPhoto(event.target.files[0]);
  };

  const handleBackPhotoChange = (event) => {
    setBackPhoto(event.target.files[0]);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleIsSoldOutChange = () => {
    setSoldout(!soldout);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    setHandCategory("");
  };

  const handleHandCategoryChange = (event) => {
    setCategory(event.target.value);
    setHandCategory(event.target.value);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const confirmChangeRequest = async () => {
    const token = Cookies.get("token");

    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/card/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify({
          name,
          description,
          price,
          sizes: size,
          category_id: category,
          soldout,
        }),
      });

      if (!response.ok) {
        alert("Что-то пошло не так, откройте консоль (F12)");
        return;
      }

      const allStrings = carouselPhotos.every(
        (item) => typeof item === "string"
      );

      if (mainPhoto || backPhoto || !allStrings) {
        const data = new FormData();

        if (mainPhoto) {
          data.append("mainPhoto", mainPhoto);
        }

        if (backPhoto) {
          data.append("backPhoto", backPhoto);
        }

        if (!allStrings) {
          carouselPhotos.forEach((file) => {
            if (file instanceof File) {
              data.append("carouselPhotos[]", file);
            }
          });
        }

        const response2 = await fetch(`${API_URL}/api/card/${id}/photos`, {
          method: "POST",
          headers: {
            token: token,
          },
          body: data,
        });

        if (response2.ok) {
          alert("Успешно обновлено!");
          setTimeout(() => {
            navigate("/home");
          }, 1000);
        } else {
          alert("Что-то пошло не так, откройте консоль (F12)");
        }
      } else {
        alert("Успешно обновлено!");
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      alert("Что-то пошло не так, откройте консоль (F12)");
    }
  };

  const confirmDeleteRequest = async () => {
    const token = Cookies.get("token");

    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/card/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });

      if (response.ok) {
        alert("Успешно удалено!");
        setTimeout(() => {
          navigate("/home");
        }, 1000);
      } else {
        alert("Что-то пошло не так, откройте консоль (F12)");
      }
    } catch (error) {
      console.log(error);
      alert("Что-то пошло не так, откройте консоль (F12)");
    }
  };

  const findNameByID = (id, array) => {
    const foundObject = array.find((obj) => obj.id === id);
    setCategory(foundObject ? foundObject.name : "");
  };

  if (isLoading) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="edit-mode">
      <div className="edit-header">
        Название
        <input
          className="edit-input"
          type="text"
          placeholder="..."
          value={name}
          onChange={handleNameChange}
        />
      </div>

      <div className="edit-header">
        Цена
        <input
          className="edit-input"
          type="number"
          placeholder="RUB"
          value={price}
          onChange={handlePriceChange}
        />
      </div>

      <div className="edit-header">
        Категория
        <select
          className="edit-input"
          value={category}
          onChange={handleCategoryChange}
        >
          <option value="">Не выбрано</option>
          {allCategories.map((el, i) => (
            <option value={el.name} key={i}>
              {el.name}
            </option>
          ))}
        </select>
        <input
          className="edit-input"
          placeholder="Новая..."
          type="text"
          value={handCategory}
          onChange={handleHandCategoryChange}
        />
      </div>

      <div className="edit-header">
        Размеры
        <input
          className="edit-input"
          placeholder="S, L, XL, ..."
          type="text"
          value={size}
          onChange={handleSizeChange}
        />
      </div>

      <div className="edit-header">
        Описание
        <textarea
          className="edit-textarea"
          placeholder="..."
          value={description}
          onChange={handleDescriptionChange}
        />
      </div>

      <div className="edit-header checkbox">
        <div className="one-size-text">Товар продан</div>
        <div className="toggle-ios">
          <input
            type="checkbox"
            id="toggle"
            className="checkbox-ios"
            checked={soldout}
            onChange={handleIsSoldOutChange}
          />
          <label htmlFor="toggle" className="label-ios"></label>
        </div>
      </div>

      <div className="edit-header checkbox">
        <div className="one-size-text">Главное фото</div>
        <label className="custom-file-upload">
          <input type="file" onChange={handleMainPhotoChange} />
        </label>
      </div>

      <div className="edit-header checkbox">
        <div className="one-size-text">Второе главное фото</div>
        <label className="custom-file-upload">
          <input type="file" onChange={handleBackPhotoChange} />
        </label>
      </div>

      <div className="edit-header checkbox">
        <div className="one-size-text">Другие фото продукта</div>
        <input
          type="file"
          multiple
          onChange={handleFileChange}
        />
      </div>

      <div className="carousel-photos">
        <ul>
          {carouselPhotos.map((file, index) => (
            <li
              key={index}
              draggable
              onDragStart={() => handleFileDragStart(index)}
              onDragOver={handleFileDragOver}
              onDrop={() => handleFileDrop(index)}
            >
              {file.name}
              <button onClick={() => handleFileRemove(index)}>Удалить</button>
            </li>
          ))}
        </ul>
      </div>

      <div className="edit-btns">
        <button
          tabIndex="-1"
          className="edit-btn-save"
          onClick={confirmChangeRequest}
        >
          Сохранить и закрыть
        </button>

        <button
          tabIndex="-1"
          className="edit-btn-delete"
          onClick={confirmDeleteRequest}
        >
          Удалить
        </button>
      </div>
    </div>
  );
}
