import React from "react";
import ReactDOM from "react-dom/client";
import Modal from "react-modal";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import "./CSS/App.css";
import "./CSS/General.css";
import "./CSS/general-style.css";
import "./CSS/ios-toggle.css";


import LoginForm from "./components/LoginForm";
import AdminPanel from "./components/AdminPanel";
import ProtectedRoute from "./components/ProtectedRoute";
import ProductsView from "./components/ProductsView";
import ProductDetails from "./components/ProductDetails";
import ChangeCard from "./components/ChangeCard";
import AddCard from "./components/AddCard";

const API_URL = process.env.REACT_APP_API_URL;

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/login" />,
    errorElement: <Navigate to="/login" />,
  },
  {
    path: "/login",
    element: <LoginForm />,
  },
  {
    path: "/home",
    element: (
      <ProtectedRoute
        element={AdminPanel}
        apiEndpoint={`${API_URL}/api/cards`}
      />
    ),

    children: [
      {
        path: "",
        element: <ProductsView />,
      },
      {
        path: "card/:id",
        element: <ProductDetails />,
      },
      {
        path: "change/card/:id",
        element: <ChangeCard />,
      },
      {
        path: "card/new",
        element: <AddCard />,
      },
    ],
  },
]);

Modal.setAppElement("#root");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
