import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from './Card';
import Cookies from 'js-cookie';
const API_URL = process.env.REACT_APP_API_URL;

const ProductsView = () => {
  const [cards, setCards] = useState([]);
  const Navigate = useNavigate();

  useEffect(() => {
    const getCards = async () => {
      try {
        const token = Cookies.get('token');

        if (!token) {
          setCards([]);
          Navigate('/login');
        }

        const response = await fetch(`${API_URL}/api/cards`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            token: token,
          },
        });

        if (response.ok) {
          const { rows } = await response.json();
          setCards(rows);
          console.log('Данные успешно получены');
        } else {
          Cookies.remove('token');
          Navigate('/login');
        }
      } catch (error) {
        Cookies.remove('token');
        Navigate('/login');

        console.log(error);
      }
    };

    getCards();
  }, [Navigate]);

  return (
    <div className='products-view row'>
      {cards.map((el, i) => (
        <Card item={el} id={cards[i].id} key={i} />
      ))}
    </div>
  );
};

export default ProductsView;
