import React, { useState, useEffect } from "react";
import logo from "./QM_LOGO.png";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL
export default function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const Navigate = useNavigate();
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      Navigate("/home");
    }
  }, [Navigate]);

  const getLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${API_URL}/login`, {
        method: "POST",
        body: JSON.stringify({
          username: username,
          password: password,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (!response.ok) {
        setError("Неверный логин или пароль");
      } else {
        const { token } = data;
        Cookies.set("token", token, { expires: 1 });

        setError("");
        Navigate("/home");
      }
    } catch (error) {
      setError(`Что-то пошло не так`);
      console.log(error.message);
    }
  };

  return (
    <div className="central-block">
      <form onSubmit={getLogin} className="login-form">
        <div className="enter-form">
          NEDOSTUPNOSTЬ ADMINISTRATION
          <input
            className="login-input"
            type="text"
            placeholder="Login"
            id="username"
            value={username}
            onChange={handleUsernameChange}
          />
          <input
            className="login-input"
            type="password"
            placeholder="Password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <button className="btn-login" type="submit">
          {error ? <div className="error-message">{error}</div> : "OPEN"}
        </button>

        <div
          className="logo"
          style={{
            backgroundImage: `url(${logo})`,
            backgroundPosition: "center",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
            backgroundColor: "transparent",
          }}
        ></div>
      </form>
    </div>
  );
}
